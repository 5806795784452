import Modal from '../classes/modal.js';

function init() {
	const trailers = document.querySelectorAll('[js-module~="trailer"]');
	if (!trailers.length) return;

	trailers.forEach((trailer) => {
		trailer.addEventListener('click', (e) => {
			const html = `<div class="modal__dialog"><video controls autoplay muted playsinline loop><source src="${trailer.href}" type="video/mp4" /></video><button class="modal__close" type="button" aria-label="Sluiten" js-element="modalClose">&times;</button></div>`;
			new Modal('Trailer', html);
			e.preventDefault();
		});
	});
}

export default {
	init,
};
