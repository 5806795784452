/*
 * Using the native <dialog> for browsers that support it and
 * fallback to <div class="modal" role="dialog"> for older
 * browsers
 * v3.0.0
 * Inspired by https://web.dev/building-a-dialog-component/
 */

const BODY_MODAL_CLASS = 'has-modal';
const MODAL_OPEN_CLASS = 'is-open';
const prefersReducedMotion =
	window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
	window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

export default class Modal {
	constructor(type, html, { onOpen, onClose } = {}, modifiers) {
		this.html = html;
		if (!this.html) throw Error('No content provided for the modal');
		this.id = type ? type : 'GeneralModal';
		this.dialog = null;
		this.dialogBackdrop = null;

		this.isOpen = false;
		this.modifiers = modifiers; // array of additional class names
		this.supportsNativeDialogElement = false;

		// Default callbacks, can be overwritten
		this.onClose = onClose;
		this.onOpen = onOpen;

		this.checkDialogElementCompatibility();
		this.createDialog();
	}

	close() {
		this.closeDialog();
	}

	open() {
		this.showDialog();
	}

	checkDialogElementCompatibility() {
		if (typeof HTMLDialogElement === 'function') {
			this.supportsNativeDialogElement = true;
		}
	}

	createDialog() {
		const dialog = this.supportsNativeDialogElement
			? document.createElement('dialog')
			: document.createElement('div');
		dialog.id = this.id;
		dialog.setAttribute('role', 'dialog');
		dialog.setAttribute('inert', '');
		dialog.setAttribute('aria-describedby', `${this.id}Title`);
		dialog.setAttribute('aria-live', 'assertive');
		dialog.setAttribute('aria-modal', 'true');
		dialog.setAttribute('data-nosnippet', 'true');
		dialog.setAttribute('aria-hidden', 'true');

		dialog.classList.add('modal', 'is-loading');

		if (this.modifiers && Array.isArray(this.modifiers)) {
			this.modifiers.forEach((className) => {
				dialog.classList.add(className);
			});
		}

		if (this.html) {
			if (typeof this.html === 'string') {
				dialog.insertAdjacentHTML('beforeend', this.html);
			} else {
				dialog.appendChild(this.html);
			}
		}

		if (!this.supportsNativeDialogElement) this.createCustomBackdrop();
		document.body.appendChild(dialog);
		this.dialog = dialog;
		this.addDialogCloseEventListeners();
		this.showDialog();
	}

	createCustomBackdrop() {
		// it's possible that there already is a backdrop in the DOM from a
		// previous dialog
		const backdrop = document.querySelector('.modal__backdrop');
		if (backdrop) {
			this.dialogBackdrop = backdrop;
		} else {
			const customBackdrop = document.createElement('div');
			customBackdrop.classList.add('modal__backdrop');
			customBackdrop.setAttribute('aria-hidden', 'true');
			document.body.appendChild(customBackdrop);
			this.dialogBackdrop = customBackdrop;
		}
	}

	showDialog(onOpen = this.onOpen) {
		document.body.classList.add(BODY_MODAL_CLASS);
		this.dialog.classList.remove('is-loading');
		this.dialog.removeAttribute('aria-hidden');

		if (this.supportsNativeDialogElement) {
			this.dialog.showModal(); // native <dialog> show method
		} else {
			this.dialog.classList.add(MODAL_OPEN_CLASS);
			[...document.body.children].forEach((child) => {
				if (
					!child.classList.contains('modal') &&
					!child.classList.contains('modal__backdrop') &&
					child.tagName !== 'SCRIPT'
				) {
					child.setAttribute('aria-hidden', 'true');
					child.setAttribute('inert', '');
				}
			});
		}

		this.dialog.removeAttribute('inert');

		this.isOpen = true;
		if (!this.supportsNativeDialogElement) {
			this.dialogBackdrop.removeAttribute('inert');
		}

		// set focus
		const focusTarget = this.dialog.querySelector('[autofocus]');
		if (focusTarget) focusTarget.focus();

		if (typeof onOpen === 'function') {
			onOpen();
		}
	}

	// click outside the dialog handler
	lightDismiss({ target: element }) {
		if (!this.supportsNativeDialogElement) {
			if (element.className === 'modal__backdrop') this.closeDialog();
		} else {
			if (element.nodeName === 'DIALOG') {
				this.closeDialog();
			}
		}
	}

	closeDialog(onClose = this.onClose) {
		console.log('closeDialog');
		// if it's already closed, do nothing
		if (!this.dialog) return;
		if (this.supportsNativeDialogElement) {
			this.dialog.close();
		} else {
			if (this.dialogBackdrop || document.querySelector('.modal__backdrop')) {
				this.dialogBackdrop.setAttribute('inert', '');
			}
			[...document.body.children].forEach((child) => {
				if (
					!child.classList.contains('modal') &&
					!child.classList.contains('modal__backdrop') &&
					child.tagName !== 'SCRIPT'
				) {
					child.removeAttribute('aria-hidden');
					child.removeAttribute('inert');
				}
			});
		}

		this.dialog.setAttribute('inert', '');
		this.dialog.setAttribute('aria-hidden', 'true');
		this.dialog.removeAttribute('aria-live');

		if (!prefersReducedMotion) {
			const d = this.dialog;
			d.addEventListener(
				'animationend',
				function () {
					d.remove();
				},
				{ once: true }
			);
		}

		this.dialog.classList.remove(MODAL_OPEN_CLASS);
		document.body.classList.remove(BODY_MODAL_CLASS);

		if (prefersReducedMotion) {
			this.dialog.remove();
		}

		if (typeof onClose === 'function') {
			onClose();
		}

		// garbage collection
		this.dialog = null;
		window.removeEventListener('keydown', this.keyDownHandler.bind(this));
	}

	addDialogCloseEventListeners() {
		if (this.supportsNativeDialogElement) {
			this.dialog.addEventListener('click', this.lightDismiss.bind(this));
		} else {
			this.dialogBackdrop.addEventListener(
				'click',
				() => {
					this.lightDismiss({ target: this.dialogBackdrop });
				},
				{ once: true }
			);
		}

		window.addEventListener('keydown', this.keyDownHandler.bind(this));

		this.dialog
			.querySelectorAll('[js-element~="modalClose"]')
			.forEach((trigger) => {
				trigger.addEventListener(
					'click',
					() => {
						this.closeDialog();
					},
					{ once: true }
				);
			});
	}

	keyDownHandler(event) {
		if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
			this.closeDialog();
		}
	}
}
