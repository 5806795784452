import Modal from '../classes/modal.js';
import Gallery from '../classes/gallery.js';

function init() {
	// If the browser supports the dialog element, we can use it to display the gallery
	if (typeof HTMLDialogElement === 'function') {
		const galleries = document.querySelectorAll('[js-module~="gallery"]');
		if (!galleries.length) return;

		galleries.forEach((gallery) => {
			let galleryInstance = null;
			const items = gallery.querySelectorAll('[js-element~="galleryItem"]');
			if (!items.length) return;

			items.forEach((item) => {
				item.addEventListener('click', (e) => {
					const html = `<div class="modal__dialog" js-element="galleryModal">
					<img class="modal__image" src="${e.currentTarget.href}" alt="" js-element="galleryImage" />
					<button class="modal__button" type="button" aria-label="Vorige" js-element="galleryNav" data-direction="previous">&lsaquo;</button>
					<button class="modal__button" type="button" aria-label="Volgende" js-element="galleryNav" data-direction="next">&rsaquo;</button>
					<button class="modal__close" type="button" aria-label="Sluiten" js-element="modalClose">&times;</button>
					</div>`;
					new Modal(
						'Gallery',
						html,
						{
							onOpen: () => {
								galleryInstance = new Gallery(e.currentTarget.href);
							},
							onClose: () => {
								galleryInstance.destroy();
							},
						},
						['modal--gallery']
					);
					e.preventDefault();
				});
			});
		});
	}
}

export default {
	init,
};
