export default class Gallery {
	constructor(fullImageUrl) {
		this.gallery = null;
		this.images = null;
		this.fullImageUrl = fullImageUrl;
		this.currentIndex = 0;
		this.nextIndex = 0;
		this.prevIndex = 0;
		this.prevButton = document.querySelector(
			'[js-element~="galleryNav"][data-direction="previous"]'
		);
		this.nextButton = document.querySelector(
			'[js-element~="galleryNav"][data-direction="next"]'
		);
		this.imagePlaceholder = document.querySelector(
			'[js-element~="galleryImage"]'
		);
		this.start();
	}

	start() {
		this.images = window.App.gallery.images;
		this.currentIndex = this.images.indexOf(this.fullImageUrl);
		this.nextIndex = ++this.currentIndex % this.images.length;
		this.prevIndex =
			(this.images.length + this.currentIndex - 2) % this.images.length;
		this;
		this.gallery = document.querySelector('[js-element~="galleryModal"]');
		this.addEventListeners();
	}

	addEventListeners() {
		this.nextButton.addEventListener('click', this.nextImage.bind(this));

		this.prevButton.addEventListener('click', this.prevImage.bind(this));

		document.addEventListener('keydown', this.keyDownHandler.bind(this), {
			once: true,
		});
	}

	removeEventListeners() {
		this.nextButton.removeEventListener('click', this.nextImage);

		this.prevButton.removeEventListener('click', this.prevImage);
	}

	keyDownHandler(event) {
		if (event.key === 'ArrowRight') {
			this.nextImage();
		}
		if (event.key === 'ArrowLeft') {
			this.prevImage();
		}
	}

	nextImage() {
		this.imagePlaceholder.src = this.images[this.nextIndex];
		this.currentIndex = this.nextIndex;
		this.updateIndexes();
	}

	prevImage() {
		this.imagePlaceholder.src = this.images[this.prevIndex];
		this.currentIndex = this.prevIndex;
		this.updateIndexes();
	}

	updateIndexes() {
		this.nextIndex = ++this.currentIndex % this.images.length;
		this.prevIndex =
			(this.images.length + this.currentIndex - 2) % this.images.length;
		document.addEventListener('keydown', this.keyDownHandler.bind(this), {
			once: true,
		});
	}

	destroy() {
		this.removeEventListeners();
		this.gallery = null;
		this.images = null;
	}
}
